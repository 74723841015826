@import "globals.scss";
.model-window-container {
  .MuiDialogTitle-root {
    padding: 6px 2px;
    text-align: end;

    .close-button {
      color: var(--LightGrey);
    }
  }

  .shrink-header {
    height: 0;
    display: none;
  }

  .absolute-header {
    position: absolute;
    right: 0;
  }

  .MuiPaper-root {
    height: 100%;
  }

  .MuiDialogContent-root {
    overflow-y: hidden;
    padding: 0;
    display: inherit;

    @include xs {
      overflow-y: auto;
    }
  }

  &.no-mic {
    .MuiDialogContent-root {
      padding: 0 100px !important;
      text-align: center;
      flex-direction: column;

      @include xs {
        padding: 0 10px !important;
      }
    }
  }
}
