@import "globals.scss";
@font-face {
  font-family: Avenir;
  src: local("Avenir Heavy"),
    url("./assets/fonts/AvenirLTStd-Heavy.otf") format("opentype");
  font-weight: 600;
}

@font-face {
  font-family: Avenir;
  src: local("Avenir Medium"),
    url("./assets/fonts/AvenirLTStd-Medium.otf") format("opentype");
  font-weight: 500;
}

@font-face {
  font-family: Avenir;
  src: local("Avenir Book"),
    url("./assets/fonts/AvenirLTStd-Book.otf") format("opentype");
  font-weight: 400;
}

:root {
  // Black - Grey
  --Black: #000000;
  --Black-op-025: rgb(0 0 0 / 25%);
  --DarkBlack: #1d1f20;
  --Dark40: #212b3666;
  --Dark: #212b36;
  --Tuna: #383b48;
  --Vulcan: #111322;
  --BrightGray: #34374a;
  --DrankGrey: #484a55;
  --MediumGrey: #a4abb1;
  --BrownGrey: #7a7a7a;
  --Grey: #c4cdd5;
  --Grey2: #828282;
  --CoolGrey: #a4abb2;
  --LightGrey: #c4cdd5;
  --VeryLightGrey: #eaeef1;
  --PaleGrey: #f7f8fb;
  --White: #ffffff;
  --White-op-010: rgb(255 255 255 / 10%);
  --White-op-020: rgb(255 255 255 / 20%);
  --White-op-030: rgb(255 255 255 / 30%);
  --White-op-050: rgb(255 255 255 / 50%);
  --White-op-060: rgb(255 255 255 / 60%);
  --White-op-070: rgb(255 255 255 / 70%);
  --White-op-080: rgb(255 255 255 / 80%);
  --White-op-090: rgb(255 255 255 / 90%);

  // Blue
  --SkyBlue: #3a89ff;
  --LightBlue: #f4f5f7;
  --VeryLightBlue: #f5f7f9;

  // --DarkBlue: #1e2132;
  --DarkBlue: #1c1f2f;
  --EbonyClay: #252742;
  --EbonyClay2: #252737;
  --EbonyClay3: #2c2f41;
  --HavelockBlue: #5871dd;
  --OuterSpace: #1c2a2b;
  --CornflowerBlue: #6482ff;
  --CornflowerBlue-op-010: rgb(100 130 255 / 10%);
  --Astronaut: #303f7e;
  --Mirage: #1f253d;

  // Red
  --Red: #de1930;
  --LightRed: #ff5555;
  --DarkPink: #c04055;
  --Pink: #f77181;
  --LightPink: #ffa3b0;
  --NightRider: #15090f;
  --CoraBrown: #342127;
  --Brown: #c38500;

  // Green
  // --DarkGreen: #005900;
  --DarkGreen: #075809;

  // --Green: #16871c;
  --Green: #1e8624;
  --VeryBrightGreen: #2bea36;
  --BrightGreen: #58e24d;
  --LightGreen: #58b651;
  --LightGreen30: #58b65130;
  --LightGreen20: #58b65120;
  --TePapaGreen: #255a3d;

  --GreenRemo: var(--Green);
  --GreenRemoShade: var(--DarkGreen);
  --GreenRemoTint: var(--VeryBrightGreen);
  --PinkRemo: var(--Pink);
  --PinkRemoShade: var(--DarkPink);
  --Warning: var(--Red);
  --PinkRemoTint: var(--LightPink);
  --Destructive: var(--LightRed);
  --TextDark: var(--Dark);
  --Cautious: var(--Brown);

  --EventsGrayPrimay: #eceff1;
  --EventsBlueSecondary: #0c3891;
  --EventsBluePrimary: #00bcda;
  --EventsLightBlack: #2e2e2e;
  --EventsBlueAccent2: #2859bb;
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
