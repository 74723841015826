@import "globals.scss";
.scrollable-view-container {
  overflow-x: hidden;
  overflow-y: overlay;
  height: calc(100%);
  width: 100%;

  &.with-appbar {
    height: calc(100% - 64px);
  }
}
